// Import necessary utilities and actions
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
import sdkUtil from '../../util/sdkUtil'; // Assuming sdkUtil is available for objectQueryString
import { useConfiguration } from '../../context/configurationContext';

const sharetribeSdk = require('sharetribe-flex-sdk');

// Define action types for fetching latest listings
export const FETCH_SELECTED_LISTINGS_REQUEST =
  'app/SelectedListingsLandingPage/FETCH_SELECTED_LISTINGS_REQUEST';
export const FETCH_SELECTED_LISTINGS_SUCCESS =
  'app/SelectedListingsLandingPage/FETCH_SELECTED_LISTINGS_SUCCESS';
export const FETCH_SELECTED_LISTINGS_ERROR =
  'app/SelectedListingsLandingPage/FETCH_SELECTED_LISTINGS_ERROR';

// Initial state for latest listings
const initialState = {
  selectedListingsLandingPage: [],
  fetchSelectedListingsLandingPageInProgress: false,
  fetchSelectedListingsLandingPageError: null,
};

// Reducer for handling latest listings actions
const selectedListingsLandingPageReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SELECTED_LISTINGS_REQUEST:
      return {
        ...state,
        fetchSelectedListingsLandingPageInProgress: true,
        fetchSelectedListingsLandingPageError: null,
      };
    case FETCH_SELECTED_LISTINGS_SUCCESS:
      return {
        ...state,
        selectedListingsLandingPage: action.payload.data,
        fetchSelectedListingsLandingPageInProgress: false,
      };
    case FETCH_SELECTED_LISTINGS_ERROR:
      return {
        ...state,
        fetchSelectedListingsLandingPageInProgress: false,
        fetchSelectedListingsLandingPageError: action.payload,
      };
    default:
      return state;
  }
};

// Action creators
export const fetchSelectedListingsLandingPageRequest = () => ({
  type: FETCH_SELECTED_LISTINGS_REQUEST,
});

export const fetchSelectedListingsLandingPageSuccess = data => ({
  type: FETCH_SELECTED_LISTINGS_SUCCESS,
  payload: { data },
});

export const fetchSelectedListingsLandingPageError = e => ({
  type: FETCH_SELECTED_LISTINGS_ERROR,
  payload: e,
});

const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [
    'variants.scaled-small',
    'variants.scaled-medium',
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
  ];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

// Thunk action for fetching latest listings
export const fetchSelectedListingsLandingPage = config => (dispatch, getState, sdk) => {
  const sdkUtil = sharetribeSdk.util;
  dispatch(fetchSelectedListingsLandingPageRequest());
  const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);
  const queryParams = {
    include: ['images', 'author'],
    sort: 'pub_ads',
    ids: [
      '662d6d6c-dbd5-46e2-b696-68773369d3c1',
      '66319c3e-b58d-4ecd-bee7-4afb6a3fb63f',
      '6633e6a8-1263-4d4f-b935-4070eb9f0d8b',
      '6631606c-7fc0-4457-bd92-9a5aec3be903',
      '66316e9a-e749-4efe-a0b9-664cd096208b',
      '66316388-e4d7-4636-8009-00c12ee62593',
      '6609f255-562d-4278-b6e3-14bf50a07819',
      '6555c305-3c42-4af4-87d3-182a404fdc09',
      '6555c265-eace-47dd-8601-968f296fa5f6',
      '66316488-21a3-49a7-bb02-3f1cf9517003',
      '663162fa-2971-4bbf-9e2c-7e6d64f3e86d',
      '66316219-3119-43ab-896a-55cd0ae9484c',
      '66316123-070c-4132-a059-8f0f0c402042',
      '66316874-1a35-42dd-8278-4aa6a5760591',
      '6631690b-783f-454a-afd3-6b9ce3f8dfd7',
      '663169c9-e772-4dad-a919-3372c21c677b',
      '66316cf4-e967-45d4-a11a-5724d0fbecdd',
      '66316a7f-bff7-469f-9c04-5a5c492cf20f',
      '66316fd5-5311-41cc-9a61-ee2280b63e18',
      '6631715b-6525-4900-b72b-40347ce1443b',
    ],
    'fields.image': imageVariantInfo.fieldsImage,
    ...imageVariantInfo.imageVariants,
  };

  return sdk.listings
    .query(queryParams)
    .then(response => {
      const { data, included } = response.data;

      // Map the image IDs to actual image objects
      const images = included.filter(item => item.type === 'image');
      const authors = included.filter(item => item.type === 'user');

      const listingsWithImages = data.map(listing => {
        // Map the image ids to actual image objects
        const listingImages = listing.relationships.images.data.map(imgRel =>
          images.find(img => img.id.uuid === imgRel.id.uuid)
        );

        const listingAuthor = authors.find(
          author => author.id.uuid === listing.relationships.author.data.id.uuid
        );

        return { ...listing, images: listingImages, author: listingAuthor };
      });

      dispatch(addMarketplaceEntities(response));
      dispatch(fetchSelectedListingsLandingPageSuccess(listingsWithImages));
    })
    .catch(e => {
      dispatch(fetchSelectedListingsLandingPageError(storableError(e)));
      throw e;
    });
};

export default selectedListingsLandingPageReducer;
