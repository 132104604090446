import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { types as sdkTypes } from '../../util/sdkLoader';
import { apiBaseUrl } from '../../util/api';

const { LatLng } = sdkTypes;

// Action types
const FETCH_LOCATION_DATA_REQUEST = 'app/LocationPage/FETCH_LOCATION_DATA_REQUEST';
const FETCH_LOCATION_DATA_SUCCESS = 'app/LocationPage/FETCH_LOCATION_DATA_SUCCESS';
const FETCH_LOCATION_DATA_ERROR = 'app/LocationPage/FETCH_LOCATION_DATA_ERROR';
const LOCATION_NOT_FOUND_ERROR = 'app/LocationPage/LOCATION_NOT_FOUND_ERROR';

// Initial state
const initialState = {
  listings: [],
  reviews: [],
  sublocations: [],
  isLoading: false,
  error: null,
  locationNotFound: false,
};

// Reducer
export default function locationPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_LOCATION_DATA_REQUEST:
      return { ...state, isLoading: true, error: null, locationNotFound: false };
    case FETCH_LOCATION_DATA_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };
    case FETCH_LOCATION_DATA_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case LOCATION_NOT_FOUND_ERROR:
      return { ...state, isLoading: false, locationNotFound: true };
    default:
      return state;
  }
}

// Action creators
const fetchLocationDataRequest = () => ({ type: FETCH_LOCATION_DATA_REQUEST });
const fetchLocationDataSuccess = data => ({ type: FETCH_LOCATION_DATA_SUCCESS, payload: data });
const fetchLocationDataError = error => ({ type: FETCH_LOCATION_DATA_ERROR, payload: error });
const locationNotFoundError = () => ({ type: LOCATION_NOT_FOUND_ERROR });

// Helper functions
const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [
    'variants.scaled-small',
    'variants.scaled-medium',
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
  ];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

function formatLocationName(cityName) {
  return cityName
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const fetchCoordinates = async location => {
  const county = formatLocationName(location);
  const findlocation = `${county}, USA`;
  const response = await fetch(`/api/fetch-coordinates`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ findlocation }),
  });
  if (!response.ok) throw new Error(`Error fetching coordinates: ${response.status}`);
  return response.json();
};

const fetchListings = async (sdk, queryParams) => {
  const response = await sdk.listings.query(queryParams);
  const { data, included } = response.data;
  const images = included.filter(item => item.type === 'image');
  const authors = included.filter(item => item.type === 'user');

  return data.map(listing => ({
    ...listing,
    images: listing.relationships.images.data.map(imgRel =>
      images.find(img => img.id.uuid === imgRel.id.uuid)
    ),
    author: authors.find(author => author.id.uuid === listing.relationships.author.data.id.uuid),
  }));
};

const fetchSublocations = async location => {
  const response = await fetch(`/api/fetch-sublocations`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ location }),
  });

  if (!response.ok) {
    if (response.status === 404) {
      throw new Error('LOCATION_NOT_FOUND');
    }
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

// Thunk action for fetching location data
export const fetchLocationData = (location, category, config) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchLocationDataRequest());

  try {
    const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);

    const sublocations = await fetchSublocations(location);

    if (sublocations.length === 0) {
      dispatch(locationNotFoundError());
      return; // Exit the function early
    }
    const locationData = sublocations[0];

    // Extract coordinates from the sublocation data
    const coordinates = locationData ? locationData.coordinates : null;
    const origin = coordinates ? new LatLng(coordinates.lat, coordinates.lng) : null;

    const queryParams = origin
      ? {
          origin: origin,
          pub_categorySingleSelect: category,
          include: ['images', 'author'],
          perPage: 15,
          'fields.image': imageVariantInfo.fieldsImage,
          ...imageVariantInfo.imageVariants,
        }
      : {
          include: ['images', 'author'],
          pub_categorySingleSelect: category,
          perPage: 12,
          'fields.image': imageVariantInfo.fieldsImage,
          ...imageVariantInfo.imageVariants,
        };

    const listings = await fetchListings(sdk, queryParams);
    // const reviews = await fetchReviews(sdk, listings.map(listing => listing.id.uuid));
    const reviews = [];

    dispatch(fetchLocationDataSuccess({ listings, reviews, sublocations }));
  } catch (error) {
    if (error.message === 'LOCATION_NOT_FOUND') {
      dispatch(locationNotFoundError());
    } else {
      dispatch(fetchLocationDataError(storableError(error)));
    }
    throw error;
  }
};

// Function to be called by the reducer from another file
export const loadData = (params, search, config) => dispatch => {
  const { location, category } = params;
  return new Promise((resolve, reject) => {
    if (!config) {
      console.warn('Config is undefined in loadData');
      return resolve();
    }

    dispatch(fetchLocationData(location, category, config))
      .then(() => resolve())
      .catch(error => {
        reject(error);
      });
  });
};
