import React from 'react';
import { Helmet } from 'react-helmet-async';

const MAPBOX_SCRIPT_ID = 'mapbox_GL_JS';
const GOOGLE_MAPS_SCRIPT_ID = 'GoogleMapsApi';

/**
 * Include scripts (like Map Provider).
 * These scripts are relevant for whole application: location search in Topbar and maps on different pages.
 * However, if you don't need location search and maps, you can just omit this component from app.js
 * Note: another common point to add <scripts>, <links> and <meta> tags is Page.js
 *       and Stripe script is added in public/index.html
 *
 * Note 2: When adding new external scripts/styles/fonts/etc.,
 *         if a Content Security Policy (CSP) is turned on, the new URLs
 *         should be whitelisted in the policy. Check: server/csp.js
 */
export const IncludeScripts = props => {
  const { marketplaceRootURL: rootURL, maps, analytics } = props?.config || {};
  const googleAnalyticsId = analytics.googleAnalyticsId;

  const { mapProvider, googleMapsAPIKey, mapboxAccessToken } = maps || {};
  const isGoogleMapsInUse = mapProvider === 'googleMaps';
  const isMapboxInUse = mapProvider === 'mapbox';

  // Add Google Analytics script if correct id exists (it should start with 'G-' prefix)
  // See: https://developers.google.com/analytics/devguides/collection/gtagjs
  const hasGoogleAnalyticsv4Id = googleAnalyticsId?.indexOf('G-') === 0;

  // Collect relevant map libraries
  let mapLibraries = [];
  let analyticsLibraries = [];

  if (isMapboxInUse) {
    // NOTE: remember to update mapbox-sdk.min.js to a new version regularly.
    // mapbox-sdk.min.js is included from static folder for CSP purposes.
    mapLibraries.push(
      <script key="mapboxSDK" src={`${rootURL}/static/scripts/mapbox/mapbox-sdk.min.js`}></script>
    );
    // Add CSS for Mapbox map
    mapLibraries.push(
      <link
        key="mapbox_GL_CSS"
        href="https://api.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.css"
        rel="stylesheet"
        crossOrigin
      />
    );
    // Add Mapbox library
    mapLibraries.push(
      <script
        id={MAPBOX_SCRIPT_ID}
        key="mapbox_GL_JS"
        src="https://api.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.js"
        crossOrigin
      ></script>
    );
  } else if (isGoogleMapsInUse) {
    // Add Google Maps library
    mapLibraries.push(
      <script
        id={GOOGLE_MAPS_SCRIPT_ID}
        key="GoogleMapsApi"
        src={`https://maps.googleapis.com/maps/api/js?key=${googleMapsAPIKey}&libraries=places`}
        crossOrigin
      ></script>
    );
  }

  if (googleAnalyticsId && hasGoogleAnalyticsv4Id) {
    // Google Analytics: gtag.js
    // NOTE: This template is a single-page application (SPA).
    //       gtag.js sends initial page_view event after page load.
    //       but we need to handle subsequent events for in-app navigation.
    //       This is done in src/analytics/handlers.js

    analyticsLibraries.push(
      <script
        key="gtagAds.js"
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-16545056723"
        crossOrigin
      ></script>
    );

    analyticsLibraries.push(
      <script key="gtagAds dataLayer">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-16545056723');
          `}
      </script>
    );

    analyticsLibraries.push(
      <script
        key="gtag.js"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
        crossOrigin
      ></script>
    );

    analyticsLibraries.push(
      <script
        key="luckyorange"
        async
        defer
        src="https://tools.luckyorange.com/core/lo.js?site-id=258c93e6"
      ></script>
    );

    analyticsLibraries.push(
      <script key="gtag dataLayer">
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', '${googleAnalyticsId}', {
          cookie_flags: 'SameSite=None;Secure',
        });
        `}
      </script>
    );

    analyticsLibraries.push(
      <script key="hotjar">
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3685256,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </script>
    );

    analyticsLibraries.push(
      <script key="facebook-pixel-script">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '647008737615605');
          fbq('track', 'PageView');
        `}
      </script>,
      <noscript key="facebook-pixel-noscript">
        {`
        <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=647008737615605&ev=PageView&noscript=1"
        />
        `}
      </noscript>
    );

    analyticsLibraries.push(
      <meta
        name="facebook-domain-verification"
        key="fb-domain-verification"
        content="24qzaxxktp3r6j4wyr9qcwtc1wlqr1"
      />
    );

    analyticsLibraries.push(
      <script key="google-tag-manager">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PB6J9VNW');
        `}
      </script>,
      <noscript key="google-tag-manager-noscript">
        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PB6J9VNW"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}
      </noscript>
    );

    // analyticsLibraries.push(
    //   <script key="momentCRMEmbed" src="https://www.momentcrm.com/embed"></script>
    // );

    // analyticsLibraries.push(
    //   <script key="momentCRMInit">
    //     {`
    //       MomentCRM('init', {
    //         'teamVanityId': 'sweettoothsupport',
    //         'doChat': true,
    //         'doTracking': true,
    //       });
    //     `}
    //   </script>
    // );
    // analyticsLibraries.push(
    //   <script key="momentCRMEmbed" src="https://www.momentcrm.com/embed"></script>
    // );

    // analyticsLibraries.push(
    //   <script key="momentCRMInit">
    //     {`
    //       console.log('Script loaded. Checking MomentCRM:', typeof MomentCRM);

    //       // Check if MomentCRM is defined before using it
    //       if (typeof MomentCRM !== 'undefined') {
    //         console.log('MomentCRM is defined. Initializing...');
    //         MomentCRM('init', {
    //           'teamVanityId': 'sweettoothsupport',
    //           'doChat': true,
    //           'doTracking': true,
    //         });
    //       } else {
    //         console.error('MomentCRM is not defined. Make sure the script is loaded.');
    //       }
    //     `}
    //   </script>
    // );
  }

  const isBrowser = typeof window !== 'undefined';
  const isMapboxLoaded = isBrowser && window.mapboxgl;

  // If Mapbox is loaded, we can set the accessToken already here.
  // This is the execution flow with the production build,
  // since SSR includes those map libraries to <head> of the app.
  if (isMapboxInUse && isMapboxLoaded && !window.mapboxgl.accessToken) {
    // Add access token for Mapbox library
    window.mapboxgl.accessToken = mapboxAccessToken;
  }

  // If the script is added on client side as a reaction to page navigation or
  // the app is rendered on client side entirely (e.g. HMR/WebpackDevServer),
  // we need to listen when the script is loaded.
  const onMapLibLoaded = () => {
    // At this point we know that map library is loaded after it's dynamically included
    if (isMapboxInUse && !window.mapboxgl.accessToken) {
      // Add access token for Mapbox sdk.
      window.mapboxgl.accessToken = mapboxAccessToken;
    }
  };

  // React Helmet Async doesn't support onLoad prop for scripts.
  // However, it does have onChangeClientState functionality.
  // We can use that to start listen 'load' events when the library is added on client-side.
  const onChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(s =>
        [MAPBOX_SCRIPT_ID, GOOGLE_MAPS_SCRIPT_ID].includes(s.id)
      );
      if (foundScript) {
        foundScript.addEventListener('load', onMapLibLoaded, { once: true });
      }
    }
  };

  const allScripts = [...analyticsLibraries, ...mapLibraries];
  return <Helmet onChangeClientState={onChangeClientState}>{allScripts}</Helmet>;
};
