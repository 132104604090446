// Import necessary utilities and actions
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { createImageVariantConfig } from '../../util/sdkLoader';
import sdkUtil from '../../util/sdkUtil'; // Assuming sdkUtil is available for objectQueryString
import { useConfiguration } from '../../context/configurationContext';

const sharetribeSdk = require('sharetribe-flex-sdk');

// Define action types for fetching latest listings
export const FETCH_LATEST_LISTINGS_REQUEST = 'app/LatestListingsPage/FETCH_LATEST_LISTINGS_REQUEST';
export const FETCH_LATEST_LISTINGS_SUCCESS = 'app/LatestListingsPage/FETCH_LATEST_LISTINGS_SUCCESS';
export const FETCH_LATEST_LISTINGS_ERROR = 'app/LatestListingsPage/FETCH_LATEST_LISTINGS_ERROR';

// Initial state for latest listings
const initialState = {
  latestListings: [],
  fetchLatestListingsInProgress: false,
  fetchLatestListingsError: null,
};

// Reducer for handling latest listings actions
const latestListingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_LATEST_LISTINGS_REQUEST:
      return {
        ...state,
        fetchLatestListingsInProgress: true,
        fetchLatestListingsError: null,
      };
    case FETCH_LATEST_LISTINGS_SUCCESS:
      return {
        ...state,
        latestListings: action.payload.data,
        fetchLatestListingsInProgress: false,
      };
    case FETCH_LATEST_LISTINGS_ERROR:
      return {
        ...state,
        fetchLatestListingsInProgress: false,
        fetchLatestListingsError: action.payload,
      };
    default:
      return state;
  }
};

// Action creators
export const fetchLatestListingsRequest = () => ({
  type: FETCH_LATEST_LISTINGS_REQUEST,
});

export const fetchLatestListingsSuccess = data => ({
  type: FETCH_LATEST_LISTINGS_SUCCESS,
  payload: { data },
});

export const fetchLatestListingsError = e => ({
  type: FETCH_LATEST_LISTINGS_ERROR,
  payload: e,
});

const getImageVariantInfo = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [
    'variants.scaled-small',
    'variants.scaled-medium',
    `variants.${variantPrefix}`,
    `variants.${variantPrefix}-2x`,
  ];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    },
  };
};

import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLng } = sdkTypes;

// Thunk action for fetching latest listings
export const fetchLatestListings = (config, location) => async (dispatch, getState, sdk) => {
  const sdkUtil = sharetribeSdk.util;
  dispatch(fetchLatestListingsRequest());
  const imageVariantInfo = getImageVariantInfo(config.layout.listingImage);

  const queryParams =
    {
      include: ['images', 'author'],
      sort: 'pub_rank',
      perPage: 12,
      meta_isHiddenOnMainPage: false,
      'fields.image': imageVariantInfo.fieldsImage,
      ...imageVariantInfo.imageVariants,
    };


  return sdk.listings
    .query(queryParams)
    .then(response => {
      const { data, included } = response.data;

      // Map the image IDs to actual image objects
      const images = included.filter(item => item.type === 'image');
      const authors = included.filter(item => item.type === 'user');
      const listingsWithImages = data.map(listing => {
        // Map the image ids to actual image objects
        const listingImages = listing.relationships.images.data.map(imgRel =>
          images.find(img => img.id.uuid === imgRel.id.uuid)
        );

        const listingAuthor = authors.find(
          author => author.id.uuid === listing.relationships.author.data.id.uuid
        );

        return { ...listing, images: listingImages, author: listingAuthor };
      });

      dispatch(addMarketplaceEntities(response));
      dispatch(fetchLatestListingsSuccess(listingsWithImages));
    })
    .catch(e => {
      dispatch(fetchLatestListingsError(storableError(e)));
      throw e;
    });
};

export default latestListingsReducer;
