
export function generateRandomEmailPrefix(email) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const prefixLength = email.substring(0, email.indexOf('@')).length;
    let randomPrefix = '';
    for (let i = 0; i < prefixLength; i++) {
      randomPrefix += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomPrefix;
  }

  export const isGuestUser = currentUser => {
    return currentUser && currentUser.id && currentUser?.attributes?.profile?.protectedData?.isGuest;
  };