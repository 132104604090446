import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';

import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';

import css from './OrderBreakdown.module.css';

const LineItemTotalPrice = props => {
  const {
    transaction,
    isProvider,
    intl,
    customShippingInfo,
    lineItems,
    currentListing,
    orderData,
  } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  if (!processName) {
    return null;
  }
  const process = getProcess(processName);
  const isCompleted = process.isCompleted(transaction?.attributes?.lastTransition);
  const isRefunded = process.isRefunded(transaction?.attributes?.lastTransition);

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (isCompleted) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (isRefunded) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalRefunded';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  let totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  let formattedTotalPrice = formatMoney(intl, totalPrice);

  const shippingFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_FEE && !item.reversal
  );
  if (shippingFeeLineItem || orderData?.deliveryMethod === 'shipping') {
    if (customShippingInfo?.initiated !== undefined) {
      if (customShippingInfo.initiated !== 'NA' && customShippingInfo.isFound) {
        const shipppingCost = shippingFeeLineItem?.lineTotal;
        // const unitPurchase = lineItems.find(item => item.code === LINE_ITEM_UNITS && !item?.reversal);
        // const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
        totalPrice.amount = shipppingCost?.amount
          ? totalPrice.amount - shipppingCost?.amount
          : totalPrice.amount;
        totalPrice.amount = totalPrice.amount + customShippingInfo.customShippingCost * 100;
        const customTotalValue = formatMoney(intl, totalPrice);
        totalPrice.amount = totalPrice.amount - customShippingInfo.customShippingCost * 100;
        totalPrice.amount = shipppingCost?.amount
          ? totalPrice.amount + shipppingCost.amount
          : totalPrice.amount;
        formattedTotalPrice = customTotalValue;
      }
    }
  }

  // return (
  //   <>
  //     <hr className={css.totalDivider} />
  //     <div className={css.lineItemTotal}>
  //       <div className={css.totalLabel}>{totalLabel}</div>
  //       <div className={css.totalPrice}>{formattedTotalPrice}</div>
  //     </div>
  //   </>
  // );

  return (
    <>
      {shippingFeeLineItem || currentListing?.attributes?.publicData?.shippingEnabled ? (
        customShippingInfo?.initiated === 'NA' || customShippingInfo?.initiated === undefined ? (
          true ? (
            <>
              <hr className={css.totalDivider} />
              <div className={css.lineItemTotal}>
                <div className={css.totalLabel}>{totalLabel}</div>
                <div className={css.totalPrice}>{formattedTotalPrice}</div>
              </div>
            </>
          ) : null
        ) : customShippingInfo?.initiated !== 'NA' && customShippingInfo?.isFound ? (
          <div>
            <hr className={css.totalDivider} />
            <div className={css.lineItemTotal}>
              <div className={css.totalLabel}>{totalLabel}</div>
              {/* need to set the new total price after it is done */}
              <div className={css.totalPrice}>{formattedTotalPrice}</div>
            </div>
          </div>
        ) : null
      ) : (
        // <>
        //   <hr className={css.totalDivider} />
        //   <div className={css.lineItemTotal}>
        //     <div className={css.totalLabel}>{totalLabel}</div>
        //     <div className={css.totalPrice}>{formattedTotalPrice}</div>
        //   </div>
        // </>
        <>
          <hr className={css.totalDivider} />
          <div className={css.lineItemTotal}>
            <div className={css.totalLabel}>{totalLabel}</div>
            <div className={css.totalPrice}>{formattedTotalPrice}</div>
          </div>
        </>
      )}
    </>
  );
};

LineItemTotalPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemTotalPrice;
