import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SHIPPING_FEE, propTypes } from '../../util/types';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';

const LineItemShippingFeeMaybe = props => {
  const { lineItems, intl, customShippingInfo, currentListing, orderData } = props;
  const shippingFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SHIPPING_FEE && !item.reversal
  );

  let customShippingPrice = null;
  if (shippingFeeLineItem || orderData?.deliveryMethod === 'shipping') {
    if (customShippingInfo?.initiated !== undefined) {
      if (customShippingInfo.initiated !== 'NA' && customShippingInfo.isFound) {
        // const unitPurchase = lineItems.find(item => item.code === LINE_ITEM_UNITS && !item?.reversal);
        // const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
        customShippingPrice = customShippingInfo.customShippingCost * 100;
      }
    }
  }
  let freeShippingEnabled = false;
  if (currentListing?.attributes?.publicData?.freeShippingEnabled != undefined) {
    freeShippingEnabled = currentListing.attributes.publicData.freeShippingEnabled;
  }

  return shippingFeeLineItem || orderData?.deliveryMethod === 'shipping' ? (
    freeShippingEnabled ? (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.shippingFee" />
        </span>
        <span className={css.itemValue}>FREE</span>
      </div>
    ) : customShippingInfo?.initiated === false || customShippingInfo?.initiated === undefined ? (
      true ? (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <FormattedMessage id="OrderBreakdown.shippingFee" />
          </span>
          <span className={css.itemValue}>{formatMoney(intl, shippingFeeLineItem.lineTotal)}</span>
        </div>
      ) : customShippingInfo.isCustomLoading ? (
        <>
          <div className={css.loader}>
            <div className={css.spinner}></div>
          </div>
        </>
      ) : (
        <p className={css.error}>
          <FormattedMessage id="OrderBreakdown.shippingPriceCantBeDisplayed" />
        </p>
      )
    ) : customShippingInfo?.initiated !== false && customShippingInfo?.isFound ? (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.shippingFee" />
        </span>
        <span className={css.itemValue}>$ {customShippingPrice / 100}</span>
      </div>
    ) : customShippingInfo.isCustomLoading ? (
      <>
        <div className={css.loader}>
          <div className={css.spinner}></div>
        </div>
      </>
    ) : (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.shippingFee" />
        </span>
        <span className={css.itemValue}>Yet to be calculated</span>
      </div>
    )
  ) : null;
};

LineItemShippingFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemShippingFeeMaybe;
